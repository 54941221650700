@import "../../styles/variables.scss";

.side-drawer {
  height: 100%;
  background-color: $darkOpaque;
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  left: 0;
  width: 50%;
  z-index: 200;
  transform: translateY(-100%);
  transition: 500ms ease-out;
  opacity: 0;

  &__menu {
    display: flex;
    flex-direction: column;
    padding: 50px;
  }

  &__link {
    color: $contrastBlue;
    margin: 24px 0;
    font-size: larger;
    transition: 300ms;

    &:hover {
      transform: scale(1.05);
      transition: 300ms;
      color: white;
      text-decoration: none !important;
    }
  }
}

.side-drawer.open {
  transform: translateY(0);
  opacity: 1;
}

@media (max-width: 700px) {
  .side-drawer {
    width: 100%;
  }
}
