@import "../../styles/variables.scss";

.homepage {
  &__text {
    margin: auto;
    color: $contrastBlue;
    text-align: center;
    padding: 30px;
    font-size: 19px;
  }
}
