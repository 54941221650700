.teaching-page {
  &__image {
    width: 60%;
    margin: 0 auto 20px;
    position: sticky;
    top: 0;
    z-index: 1 !important;
    & img {
      width: 100%;
    }
  }
  &__quote {
    font-style: italic;
  }

  &__text {
    position: relative;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 20;
    columns: 2;
    column-gap: 2rem;
    -moz-columns: 2;
    padding: 20px;
  }
}

@media (max-width: 800px) {
  .teaching-page {
    &__text {
      columns: 1;
    }

    &__image {
      position: relative;
    }
  }
}
