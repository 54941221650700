.toolbar {
  position: fixed;
  // background-color: rgba(50, 49, 48, 0.8);
  width: 100%;
  height: 76px;
  top: 0;
  left: 0;
  z-index: 300;
  transition: 300ms;
}

.toolbar img {
  height: 75px;
}

.toolbar__navigation {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}

.toolbar__logo {
  margin-left: 1rem;
}
.toolbar__logo a {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
}

.header img {
  margin: 0 15px;
}

.toolbar_navigation-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.toolbar_navigation-items li {
  padding: 0 0.5rem;
}

.toolbar_navigation-items a {
  color: white;
  text-decoration: none;
}

.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active {
  color: rgb(185, 205, 207);
}

@media (max-width: 700px) {
  .toolbar {
    &__contact {
      display: none;
    }
  }
  .toolbar__navigation {
    padding: 0;
  }
}
