.pertubando {
  text-align: center;
  padding: 60px;
  margin-top: 20px;

  & h5 {
    padding: 20px 0;
  }

  &__tracks {
    display: flex;
    justify-content: space-evenly;
  }

  &__gallery {
    display: flex;
    margin-bottom: 60px;
    height: 200px;
  }

  &__image {
    width: 30%;
    & img {
      width: 100%;
    }
  }

  &__heading {
    margin: 60px auto;
  }
}

@media (max-width: 1200px) {
  .pertubando {
    &__gallery {
      position: sticky;
      margin-left: -60px;
      margin-right: -60px;
      flex-wrap: nowrap;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      scroll-snap-type: x mandatory;
      height: 30vh;
    }

    &__image {
      flex: 0 0 auto;
      height: 100%;
      width: 40%;
      scroll-snap-align: center;
    }
  }

  .pertubando div {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
  }

  .pertubando div::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
}

@media (max-width: 700px) {
  .pertubando {
    padding: 25px;

    &__gallery {
      position: relative;
      height: 40vh;
      top: 75px;
      margin-left: -25px;
      margin-right: -25px;
    }

    &__image {
      width: 50%;
    }
  }
}
