.jazz {
  display: flex;
  flex-wrap: wrap;
  height: 100%;

  &__text {
    &__container {
      width: 70%;
    }
    padding: 30px;
  }

  &__image {
    min-width: 300px;
    width: 30%;

    & img {
      width: 100%;
      position: sticky;
      top: 100px;
    }
  }
}

.joy {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;

  &__image {
    max-width: 700px;
    min-width: 200px;
    width: 50%;

    img {
      width: 100%;
    }
  }
  &__text {
    padding: 30px;
    min-width: 200px;
    width: 40%;
  }

  &__tracks {
    list-style: none;
    margin-top: 28px;

    width: 100%;
    &__track {
      display: flex;
      align-items: center;
      span {
        margin-left: 8px;
      }
    }
  }
}

@media (max-width: 1100px) {
  .jazz {
    flex-direction: column-reverse;

    &__text__container {
      width: 100%;
    }

    &__image {
      width: 60%;

      margin: 50px auto 0px;
    }
  }
}

@media (max-width: 768px) {
  .joy {
    &__text {
      width: 100%;
    }
  }
}
