.hero {
  height: 80vh;
  overflow: hidden;
  position: relative;
  & img {
    width: 100%;
  }

  &__overlay {
    width: 100%;
    position: absolute;
    top: 50%;
    margin: auto;

    & h1 {
      font-size: 4rem;
      border: 1px solid white;
      background-color: rgba(0, 0, 0, 0.4);
      padding: 40px;
      margin: 120px;
      text-align: center;
      width: fit-content;
      margin: auto;
    }
  }
}

@media (max-width: 700px) {
  .hero {
    height: 60vh;
    &__overlay {
      top: 40%;

      & h1 {
        font-size: 2rem;
      }
    }
  }
}
