@import "../../styles/variables.scss";

.trio-hero {
  padding: 25px 0;
  color: $contrastBlue;
  background-color: white;
  overflow: hidden;
  position: relative;

  &__image {
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 80vh;

    & img {
      width: 100%;
    }
  }

  &__title {
    text-align: center;
    margin: 25px auto;
    color: $contrastBlue !important;
    font-weight: 700;
    font-size: 2.3rem;
  }

  &__audio-players {
    position: absolute;
    bottom: 0px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(5px);
  }
}

.more-button {
  margin: 40px auto;
  position: relative;
  z-index: 20;

  &__container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
