@import "../../styles/variables.scss";

.contact-modal {
  text-align: center;
  padding: 30px;
  color: $darkGrey;

  & a {
    color: $darkGrey;
  }

  button {
    color: white;
  }

  background-color: $contrastBlue;
}
