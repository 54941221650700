@import "../../styles/variables.scss";

/* Main CSS */
.grid-wrapper > div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.grid-wrapper > div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  // border-radius: 5px;
  cursor: pointer;
}

.grid-wrapper {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-rows: 200px;
  grid-auto-flow: dense;
}
.grid-wrapper .wide {
  grid-column: span 2;
}
.grid-wrapper .tall {
  grid-row: span 2;
}
.grid-wrapper .big {
  grid-column: span 2;
  grid-row: span 2;
}

.image-container {
  position: relative;

  &__description {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    color: white;
    font-size: 0.9rem;
    background-color: rgba(0, 0, 0, 0.7);
    // border-radius: 0 0 5px 5px;

    & a {
      color: $contrastBlue;
    }
  }
}
