@import "../../styles/variables.scss";

$active-color: #00aeb0;

* {
  box-sizing: border-box;
}

button {
  background: none;
  border: none;
  cursor: pointer;
}

.audio-player {
  position: fixed;
  z-index: 2000;
  background-color: $darkGrey;
  width: 350px;
  border-radius: 20px;
  padding: 24px;
  box-shadow: 0 28px 28px rgba(0, 0, 0, 0.2);
  margin: auto;
  left: 50%;
  top: 0;
  transform: translate(-50%);
  transition: 300ms;
  overflow: hidden;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
  margin-top: 5px;
}

.audio-player--toggle {
  cursor: pointer;
  position: absolute;
  top: -10px;
  left: 0;
  width: 100%;
  margin-bottom: 20px;
}

.audio-player--mini {
  position: fixed;
  z-index: 2000;
  background-color: $darkOpaque;
  width: 350px;
  border-radius: 20px;
  padding: 24px;
  box-shadow: 0 28px 28px rgba(0, 0, 0, 0.2);
  margin: auto;
  left: 50%;
  top: 0;
  transform: translate(-50%);
  overflow: hidden;
  transition: 300ms;
  margin-top: 5px;

  &:hover {
    background-color: $mediumOpaque;
  }
}
@media (max-width: 700px) {
  .audio-player {
    width: 250px;
  }
  .audio-player--mini {
    width: 250px;
    padding: 24px 8px;
  }
}

.audio-artwork-wrapper {
  object-position: center;
  height: 160px;
  width: 100%;
  border-radius: 15% 15% 0 0;
  overflow: hidden;
  margin: auto;
  & img {
    width: 100%;
  }
}

.artwork {
  display: block;
  margin: auto;
  object-fit: cover;
}

.track-info {
  position: relative;
  text-align: center;
  z-index: 1;
  position: relative;
  color: $contrastBlue;
  padding-top: 9px;
}

.disclaimer {
  font-size: 10px;
  color: $contrastBlue;
  height: 5px;
  margin: 8px;
  text-align: center;
}

.title {
  font-weight: 700;
  margin-bottom: 4px;
  margin-top: 4px;
}

.artist {
  font-weight: 300;
  margin-top: 0;
}

.audio-controls {
  display: flex;
  justify-content: space-between;
  width: 75%;
  margin: 0 auto 15px;
}

button {
  border: none;
  outline: none;
  &:focus {
    border: none;
    outline: none;
  }
}
.audio-controls .prev svg,
.audio-controls .next svg {
  width: 25px;
  height: 25px;
  outline: none;
  border: none;
  color: $contrastBlue;
  &:focus {
    outline: none;
    border: none;
  }

  &:hover {
    color: blue;
  }
}

.audio-controls .play svg,
.audio-controls .pause svg {
  height: 30px;
  width: 30px;
  color: $contrastBlue;
}

@keyframes slideDown {
  from {
    transform: translateY(-50px);
    opacity: 0;
    height: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
    height: fit-content;
  }
}

.nav-dropdowns {
  display: flex;
  flex: 1;
}

.navbar-languages {
  height: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  margin-top: -10px;
}

input[type="range"] {
  height: 5px;
  -webkit-appearance: none;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 8px;
  background: white;
  transition: background 0.2s ease;
  cursor: pointer;
}

.tracklist {
  margin-top: 20px;
  max-height: 100vh;
  overflow: auto;
  width: 100%;
  text-align: center;
  background-color: $darkOpaque;
  border: 1px solid $contrastBlue;
  border-radius: 5px;
  padding: 5px;

  div {
    cursor: pointer;
    transition: 200ms;
    margin: 5px 0;
    font-weight: 300;

    &:hover {
      font-weight: 500;
      transition: 200ms;
    }
  }
}

.track {
  color: white;
  font-size: 12px;
  &--active {
    color: $contrastBlue;
  }
}

.fav-track {
  color: grey;
  margin-left: 5px;
  font-size: 10px;
}

.liked {
  color: $contrastBlue;
}

.audio-active {
  position: absolute;
  left: 16px;
  top: -9px;
  height: 17px;
  width: 24px;
  display: flex;
  justify-content: space-evenly;
  transform: rotate(180deg);

  &__bar {
    width: 15%;
    background-color: $contrastBlue;
    animation: dance 1s infinite;
  }
}

@keyframes dance {
  0% {
    height: 15%;
  }
  25% {
    height: 60%;
  }
  50% {
    height: 35%;
  }
  75% {
    height: 75%;
  }
  100% {
    height: 20%;
  }
}

.mini-pause {
  position: absolute;
  top: -13px;
  right: 16px;
  color: $contrastBlue;
}

@keyframes music {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0.9);
  }
}
