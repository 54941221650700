.audio-page {
  background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
    url("../../assets/imgs/orenbw.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;

  &__content {
    padding: 40px;
    max-width: 1400px;
    margin: auto;
  }

  &__group {
    padding: 40px 0;
  }
}

.audio-item {
  display: flex;
  align-items: center;

  &__player {
    margin-right: 30px;
  }

  &__image {
    width: 300px;
    margin: 16px 0;
  }
}

@media (max-width: 768px) {
  .audio-page {
    &__content {
      padding: 0px;
    }
  }
}
