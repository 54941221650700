@import "./variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;700&display=swap");
body {
  background-color: rgb(26, 25, 25);
  color: rgb(229, 246, 249);
  font-family: "Comfortaa", cursive;
}

iframe {
  max-width: 100%;
  border-radius: 5px;
}

audio {
  max-width: 100%;
}

video {
  max-width: 100%;
  border-radius: 10px;
}

.activity-scroller {
  height: 100vh;
  overflow: auto;
}

.btn {
  margin: 3px;
}

.button-link {
  color: $contrastBlue;
  border: 1px solid $contrastBlue;
  border-radius: 5px;
  padding: 9px;

  &:hover {
    text-decoration: none;
    background-color: $contrastBlue;
    color: white;
    transition: 300ms;
  }
}

.page-content {
  animation: pageLoad 1000ms ease-in-out;
  padding: 40px;
  padding-top: 100px;
  min-height: 100vh;
  margin-top: 60px;
  max-width: 1400px;
  margin: auto;
}

.page-entrance {
  animation: pageLoad 1000ms ease-in-out;
}

@keyframes pageLoad {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.biog-page {
  display: flex;
  height: 100%;

  &__image {
    width: 30%;

    & img {
      width: 100%;
      position: sticky;
      top: 100px;
    }
  }

  &__text {
    flex: 1;
    margin: 0 20px;
  }
}

.contact-bar {
  display: inline-block;
  width: 100%;
  background-color: rgba(50, 49, 48, 0.8);
  padding: 10px;
}

.content {
  padding: 20px;
}

.homepage-features {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.featured-cd-card {
  background-color: rgb(19, 18, 18);
  border-radius: 5px;
  box-shadow: 1px 1px 2px rgb(57, 56, 56);
  margin: 25px;
  padding: 10px;
  /* height: 300px; */
  border-radius: 5px;
}

.slider {
  &:hover {
    border: 2px solid white;
    transition: 500ms;

    .slider-text {
      font-weight: bold;
      & a {
        text-decoration: none !important;
      }
    }
  }
}

.right-slider {
  width: 220px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: whitesmoke;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: 3px solid grey;
  padding: 10px;
  position: relative;
  margin-bottom: 20px;
  height: 300px;
  color: white;
  border-radius: 10px;
  cursor: pointer;
}

.slider-text {
  color: white;
  border: 1px solid white;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  text-decoration: none;
}

.left-slider {
  width: 220px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: 3px solid grey;
  padding: 10px;
  margin-bottom: 20px;
  height: 300px;
  color: white;
  border-radius: 10px;
  cursor: pointer;
}

.link {
  color: rgb(66, 223, 221);
}

.link:hover {
  text-decoration: none;
  color: rgb(147, 230, 233);
}

.home-project-card {
  padding: 5px;
  width: 100%;
  height: 300px;
  text-align: center;
  overflow: hidden;
  border-radius: 5px;
}

.home-project-card-overlay {
  border-radius: 5px;
}

.home-project-card-image-wrapper {
  width: 100%;
  border-radius: 5px;
  height: 150px;
  overflow: hidden;
}

.home-project-card img {
  border-radius: 5px;
  max-width: 100%;
}

.product-card {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 10px;
  background-color: rgb(33, 33, 33);
  margin: 20px;
}

.product-card-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 65px;

  padding: 10px;
}

.project-card {
  padding: 30px;
  background-color: rgb(34, 33, 33);
  margin: 25px;
  border-radius: 5px;
}

.project-card-left-panel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.single-product-description {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.slider-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.special-btn {
  background-color: $contrastBlue;
  color: black !important;
  border: none;
  border-radius: 10px;
  padding: 7px;
  margin: 2px;
}

.special-btn:hover {
  background-color: rgb(57, 56, 56);
  cursor: pointer;
  color: white !important;
  text-decoration: none;
}

.special-btn-outline {
  border: 1px solid black;
  padding: 7px;
  border-radius: 10px;
  background-color: white;
}

.special-btn-outline:hover {
  background-color: #eee;
}

.titlebar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

/*image overlays*/

.main-box {
  width: 100%;
  position: relative;
  float: left;
  margin: 0 4px;
}
.main-box img {
  position: relative;
  z-index: 1;
}

.overlay {
  position: absolute;
  z-index: 2;
  top: 0;
  opacity: 0;
  color: black;
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  background-color: #d5d5d5e2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5%;
  text-align: center;
}

.overlay p {
  font-weight: 500;
}
.main-box:hover .overlay {
  opacity: 1;
}

.gallery-img {
  width: 100%;
}

.gallery-img:hover {
  cursor: pointer;
  width: 105%;
  transition: 500ms;
}

.img-wrap {
  overflow: hidden;
  height: 0;
  padding: 50% 0;
  /* padding controls height, will always be perfectly square regardless of width */
  position: relative;
  opacity: 0.8;
}
.img-wrap img {
  min-width: 150%;
  min-height: 150%;
  max-width: 120%;
  position: absolute;
  top: 0;
  right: 0;
}

/* modal styles */
.backdrop {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}
.backdrop img {
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 40px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  border: 3px solid white;
}

.backdrop h5 {
  text-align: center;
  color: white;
}

@media (max-width: 800px) {
  .biog-page {
    flex-direction: column;

    &__image {
      width: 60%;
      margin: 0 auto 30px;
    }
  }
}
