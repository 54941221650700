@import "../../styles/variables.scss";
audio {
  display: none;
}

.player-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.play-pause {
  border: none;
  outline: none;
  background-color: transparent;
  color: $contrastBlue;
  font-size: 60px;

  &--small {
    font-size: 40px;
  }
}

.track-details {
  // color: $darkGrey;

  &--active {
    // color: $darkGrey;
  }
}
