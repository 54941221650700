@import "../../styles/variables.scss";
.course {
  $mainPadding: 30px;

  &__container {
    height: 100vh;
    background-color: $lightBackground;
  }

  &__top {
    display: flex;
  }

  &__content {
    display: flex;
    margin-top: 80px;
    position: relative;
  }

  &__scroller {
    overflow: scroll;
    height: 400px;
  }

  &__text-container {
    background-color: $lightBackground;
    color: black;
    height: 100vh;
    flex: 1;
    padding: 10px;
  }

  &__video-container {
    width: 90vh;
    aspect-ratio: 16/9;
    & iframe {
      width: 100%;
      aspect-ratio: 16/9;
    }
  }

  &__sections-menu {
    width: 250px;
    flex-shrink: 0;
    height: 100vh;
    background-color: black;
    padding: 20px;
    padding-top: 100px;

    &__item {
      color: black;
      padding: 5px;
      background-color: black;
      width: 100%;
      height: 200px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 16px 0;
      background-color: white;
      & iframe {
        height: 100%;
      }

      &__desc {
        font-style: italic;
        font-size: smaller;
      }
    }
  }

  &__header {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px $mainPadding;
    padding-right: 200px;
    height: 80px;

    &__box {
      display: flex;
      align-items: center;
      & h2 {
        margin-right: 20px;
      }
    }
  }
}
